<template>
  <b-row>
    <b-col cols="12" class="mb-0">
      <h3 class="mb-0 h3 text-primary">
        {{ $t("Other Terms") }}
      </h3>
      <hr />
    </b-col>

    <!-- <b-col cols="12" class="mt-1">
      <h5>{{ $t("Prepayment") }}</h5>
      <hr />
    </b-col> -->

    <b-col md="6">
      <b-form-group
        :label="$t('Prepayment Type')"
        label-for="v-prepayment-type"
      >
        <span class="h5 text-uppercase">
          {{ terms && terms.prepay_type ? terms.prepay_type : "None" }}
        </span>
        <!-- <b-form-select
          id="v-prepayment-type"
          v-model="terms.prepay_type"
          :options="$t('leasePrepaymentTypeList')"
          size=""
          class=""
        /> -->
      </b-form-group>
    </b-col>

    <b-col md="6">
      <b-form-group
        :label="$t('Prepayment Value')"
        label-for="v-prepayment-value"
      >
        <span class="h5 text-uppercase">
          {{ terms && terms.prepay_value ? terms.prepay_value : "None" }}
        </span>
        <!-- <b-form-input
          v-model="terms.prepay_value"
          type="number"
          id="v-prepayment-value"
          placeholder=""
        /> -->
      </b-form-group>
    </b-col>

    <b-col cols="12" class="mt-2">
      <h5>{{ $t("Late Payment") }}</h5>
      <hr />
    </b-col>

    <b-col md="4">
      <b-form-group
        :label="$t('Late Payment Type')"
        label-for="v-late-payment-type"
      >
        <span class="h5 text-uppercase">
          {{ terms && terms.latepay_type ? terms.latepay_type : "None" }}
        </span>
        <!-- <b-form-select
          id="v-late-payment-type"
          v-model="terms.latepay_type"
          :options="$t('leaseLatePaymentTypeList')"
          size=""
          class=""
        /> -->
      </b-form-group>
    </b-col>

    <b-col md="4">
      <b-form-group :label="$t('Late Interest')" label-for="v-late-interest">
        <span class="h5 text-uppercase">
          {{
            terms && terms.latepaym_interest ? terms.latepaym_interest : "None"
          }}
        </span>
        <!-- <b-form-input
          v-model="terms.latepaym_interest"
          type="number"
          id="v-late-interest"
          placeholder=""
        /> -->
      </b-form-group>
    </b-col>

    <b-col md="4">
      <b-form-group :label="$t('Late Penalty')" label-for="v-latepaym-penalty">
        <span class="h5 text-uppercase">
          {{ terms && terms.latepay_value ? terms.latepay_value : "None" }}
        </span>
        <!-- <b-form-input
          v-model="terms.latepay_value"
          id="v-latepaym-penalty"
          placeholder=""
        /> -->
      </b-form-group>
    </b-col>

    <b-col cols="12" class="mt-2">
      <h5>{{ $t("Rent Increase") }}</h5>
      <hr />
    </b-col>

    <b-col md="6">
      <b-form-group :label="$t('Increase Type')" label-for="v-increase-type">
        <span class="h5 text-uppercase">
          {{ terms && terms.increaseType ? terms.increaseType : "None" }}
        </span>
        <!-- <b-form-select
          id="v-increase-type"
          v-model="terms.increaseType"
          :options="$t('leaseIncreaseTypeList')"
          size=""
          class=""
        /> -->
      </b-form-group>
    </b-col>

    <b-col md="6">
      <b-form-group :label="$t('Increase Value')" label-for="v-increase-value">
        <span class="h5 text-uppercase">
          {{ terms && terms.increaseValue ? terms.increaseValue : "None" }}
        </span>
        <!-- <b-form-input
          v-model="terms.increaseValue"
          type="number"
          id="v-increase-value"
          placeholder=""
        /> -->
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import {
  BFormDatepicker,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BCardText,
} from "bootstrap-vue";

export default {
  components: {
    BFormDatepicker,
    BFormSelect,
    BFormTextarea,
    BCardText,

    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  props: {
    terms: {
      type: Object,
      // required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
