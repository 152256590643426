<template>
  <!-- <b-card-code title="Repeating Forms"> -->
  <div>
    <b-col cols="12" class="mt-1">
      <small class="mb-0 h3 text-primary">
        {{ title }}
      </small>
      <hr />
    </b-col>

    <b-col cols="12" class="mb-2">
      <div class="" title="Entity Entry">
        <div>
          <b-form
            ref="form"
            class="repeater-form"
            @submit.prevent="repeateAgain"
          >
            <!-- Row Loop -->
            <b-row
              v-for="(entity, index) in entities"
              :id="entity.id"
              :key="index"
              ref="row"
            >
              <!-- Entity Type -->
              <b-col md="4">
                <b-form-group :label="$t('Entity Type')" label-for="item-type">
                  <span class="h5 text-uppercase">
                    {{ entity.type ? entity.type : "None" }}
                  </span>
                  <!-- <b-form-select
                    id="item-type"
                    v-model="entity.type"
                    :options="$t('entityTypeList')"
                    size=""
                    class=""
                  /> -->
                </b-form-group>
              </b-col>

              <!-- Entity Name -->
              <b-col md="8">
                <b-form-group :label="$t('Full Name')" label-for="item-name">
                  <span class="h5 text-uppercase">
                    {{ entity.fullname ? entity.fullname : "None" }}
                  </span>
                  <!-- <b-form-input
                    id="item-name"
                    v-model="entity.fullname"
                    type="text"
                    placeholder="John Smith"
                  /> -->
                </b-form-group>
              </b-col>

              <!-- Entity address -->
              <b-col md="12">
                <b-form-group
                  :label="$t('Entity Address')"
                  label-for="item-address"
                >
                  <span class="h5 text-uppercase">
                    {{ entity.address ? entity.address : "None" }}
                  </span>
                  <!-- <b-form-input
                    id="item-address"
                    v-model="entity.address"
                    type="text"
                    placeholder="1 Smith St, Sydney 2000 NSW Australia"
                  /> -->
                </b-form-group>
              </b-col>

              <!-- Entity Id type -->
              <b-col md="6">
                <b-form-group
                  :label="$t('Entity ID Type')"
                  label-for="item-id-type"
                >
                  <span class="h5 text-uppercase">
                    {{ entity.entityIdType ? entity.entityIdType : "None" }}
                  </span>
                  <!-- <b-form-select
                    id="item-id-type"
                    v-model="entity.entityIdType"
                    :options="$t('entityIdTypeList')"
                    size=""
                    class=""
                  /> -->
                </b-form-group>
              </b-col>

              <!-- Entity Id Value -->
              <b-col md="6">
                <b-form-group
                  :label="$t('Entity ID Value')"
                  label-for="item-id-value"
                >
                  <span class="h5 text-uppercase">
                    {{ entity.entityId ? entity.entityId : "None" }}
                  </span>
                  <!-- <b-form-input
                    id="item-id-value"
                    v-model="entity.entityId"
                    type="text"
                    placeholder="1242523"
                  /> -->
                </b-form-group>
              </b-col>

              <!-- Entity phone -->
              <b-col md="6">
                <b-form-group :label="$t('Phone')" label-for="item-phone">
                  <span class="h5 text-uppercase">
                    {{ entity.phone ? entity.phone : "None" }}
                  </span>
                  <!-- <b-form-input
                    id="item-phone"
                    v-model="entity.phone"
                    type="text"
                    placeholder="+61 2 9000 8000"
                  /> -->
                </b-form-group>
              </b-col>

              <!-- Entity email -->
              <b-col md="6">
                <b-form-group :label="$t('Email')" label-for="item-email">
                  <span class="h5 text-uppercase">
                    {{ entity.email ? entity.email : "None" }}
                  </span>
                  <!-- <b-form-input
                    id="item-email"
                    v-model="entity.email"
                    type="email"
                    placeholder="john@smith.com"
                  /> -->
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </div>
    </b-col>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormSelect,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { cloneStruct } from "@/@core/utils/utils";
import entity from "@/libs/struct/entity";

export default {
  components: {
    BFormSelect,
    BCardCode,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  // mixins: [heightTransition],
  props: {
    entities: {
      type: Array,
      required: true,
    },
    allowSelf: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      entityType: null,
      entityIdType: null,
      entityIdValue: "",
    };
  },
  mounted() {
    // this.initTrHeight();
    console.log("show summary: ", this.entities);
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    // initTrHeight() {
    //   this.trSetHeight(null);
    //   this.$nextTick(() => {
    //     this.trSetHeight(this.$refs.form.scrollHeight);
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
